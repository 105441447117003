import React from 'react';

import Pdf from "../../../assets/images/sws23-agenda.jpg";

const Agenda = () => {
  return (
    <div style={{display:"flex",justifyContent:"center"}}>
      <img src={Pdf} style={{maxWidth:1000, width:"100%"}} alt="Winter Summit 2023 Agenda" height="100%" />
    </div>
  )
}

export default Agenda